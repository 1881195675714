.header {
    height: 60px;
    position: relative;
}

.logo-big {
    width: 80px;
    vertical-align: middle;
}

.navbar-header {
    display: flex;
    align-items: center;
}

.navbar-header .navbar-brand h1 {
    font-size: 18px;
    margin-top: 12px;
}

@media only screen and (max-width: 600px) {
    .logo-big {
        width: 60px !important;
    }
}

nav.navbar {
    background-color: #0098da;
    color: white;
}

.remove-caret {
    cursor: pointer;
}

.user-size li:hover {
    color: black;
    font-weight: bolder;
    /* background: black; */
}

.user-size li {
    color: grey;
    font-weight: normal;
    border-bottom: 1px solid grey;
    /* background: black; */
}

nav.navbar .dropdown-menu a {
    color: grey;
    font-weight: normal;
    /* letter-spacing: 2px; */
    /* background: black; */
}

nav.navbar .dropdown-menu a:hover {
    color: black;
    font-weight: bolder;
    letter-spacing: 2px;
    /* background: black; */
}

nav.navbar .user-size {
    min-width: 180px !important;
}

/* .remove-caret:after {
    display: none;
} */

.fixed-bottom, .fixed-top {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

nav.navbar {
    padding: 0 15px;
    border-radius: 0;
    box-shadow: 0 1px 15px 1px rgba(52, 40, 104, .08);
    z-index: 1000;
    width: 100%;
    height: 100%;
}

.navbar-holder {
    width: 100%;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    vertical-align: middle !important;
}

.navbar-brand {
    display: inline-block;
    padding-top: .25rem;
    padding-bottom: .25rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}



.navbar a {
    color: inherit;
}

.menu-btn {
    margin-right: 0;
    font-size: 1.2em;
    vertical-align: middle;
    padding: 0;
}

@media (max-width: 1200px) {
    .navbar .menu-btn {
        margin-right: 20px;
        font-size: 1.2rem;
        transition: all .7s;
    }
}

.navbar .menu-btn span {
    background: #aea9c3;
    width: 20px;
    height: 2px;
    display: block;
    margin: 7px 0;
    border-radius: 35px;
    transition: all .3s cubic-bezier(.81, -.33, .345, 1.375);
}

.navbar .menu-btn span:first-of-type {
    margin-top: 0;
    transition: all .6s;
}

nav.navbar .menu-btn.active span:first-of-type {
    margin-top: 0;
}

.navbar .menu-btn span:first-of-type {
    -webkit-transform: translateY(16px);
    transform: translateY(16px);
}


.navbar .menu-btn.active span:first-of-type {
    -webkit-transform: none;
    transform: none;
}


.navbar .menu-btn span:nth-of-type(2) {
    position: relative;
    width: 30px;
}

nav.navbar .menu-btn.active span:nth-of-type(2) {
    -webkit-transform: none;
    transform: none;
}

.navbar .menu-btn span:nth-of-type(2) {
    -webkit-transform: none;
    transform: none;
}


.navbar .menu-btn.active span:nth-of-type(2) {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}


.navbar .menu-btn span:last-of-type {
    position: relative;
    width: 15px;
}

.navbar .menu-btn span:last-of-type {
    margin-top: 0;
    transition: all .3s;
}

.navbar .menu-btn.active span:last-of-type {
    -webkit-transform: translateX(15px);
    transform: translateX(15px);
    margin-top: 0;
}

.navbar .menu-btn span:last-of-type {
    -webkit-transform: translateY(-16px);
    transform: translateY(-16px);
}


.navbar .menu-btn.active span:last-of-type {
    -webkit-transform: none;
    transform: none;
}


.navbar .menu-btn span:first-of-type {
    -webkit-transform: translateY(16px);
    transform: translateY(16px);
}

.pull-right {
    display: flex !important;
    float: right;
    padding-left: 0;
    list-style: none;
}

a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
}

.nav-link {
    display: block;
    padding: .7rem 1rem;
}

.custom-toggle {
    display: block;
    padding: .7rem 1rem !important;
}


.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

nav.navbar .user-size.dropdown-menu {
    min-width: 250px;
    overflow: hidden;
}

nav.navbar .user-size {
    min-width: 180px !important;
}

@media (max-width: 576px) {
    nav.navbar .dropdown-menu {
        right: auto;
        left: -50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}

nav.navbar .dropdown-menu {
    right: 0;
    min-width: 300px;
    left: auto;
    margin-top: 0;
    padding: 0;
    max-width: 400px;
    border-radius: 4px;
    border: none;
    box-shadow: 0 0 30px rgba(0, 0, 0, .15);
}

.dropdown-menu.show {
    display: block;
}

.dropdown-item:active {
    background-color: #fff !important;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem;
}

/* .pp-nav-toggle {
    position: relative;
    /* top: 8px; 
padding: 0;
text-align: left;
}

.paper-nav-toggle {
    /* padding: 6px 0 0 0; 
display: block;
margin: 0 auto;
display: block;
height: 44px;
width: 44px;
z-index: 2001;
border-bottom: none !important;
text-align: center;
}

.paper-nav-toggle {
    width: 25px;
    height: 25px;
    cursor: pointer;
    text-decoration: none;
}

.paper-nav-toggle i {
    position: relative;
    display: inline-block;
    width: 25px;
    height: 4px;
    color: #fff;
    font: bold 14px/.4 Helvetica;
    text-transform: uppercase;
    text-indent: -55px;
    background: #fff;
    transition: all .2s ease-out;
}

.paper-nav-toggle i::before {
    top: -8px;
}

.paper-nav-toggle i::after {
    bottom: -8px;
}

.paper-nav-toggle i::after, .paper-nav-toggle i::before {
    content: '';
    width: 25px;
    height: 4px;
    background: #fff;
    position: absolute;
    left: 0;
    transition: all .2s ease-out;
}

*/