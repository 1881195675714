.css-hip9hq-MuiPaper-root-MuiAppBar-root {
    background-color: #000 !important;
}

.zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.switch {
    border: 1px solid #000 !important;
    border-radius: 4px !important;
    background-color: #000 !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    margin-left: 10px;
}



.switch-on {
    background-color: #000 !important;
    border-color: #000 !important;
    border: 1px solid #000 !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    border: none !important;
    border-radius: 4px !important;
}

.switch-off {
    background-color: #d3d4d5 !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    border: none !important;
    border-radius: 4px !important;
}

.switch-handle {
    position: relative;
    margin: 0 auto;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 100%;
    width: 0px;
    border-width: 0 1px;
    border-radius: 4px !important;
    background-color: #fff !important;
}

/* .btn {

    border: none !important;
    border-radius: 4px !;
} */

.content-inner {
    width: calc(100% - 250px);
    margin-left: 250px;
    transition: all .3s ease;
}



@media (max-width: 576px) {
    .content-inner {
        margin-left: 0 !important;
    }
}

.content-inner.active, .content-inner.compact {
    width: 100%;
    margin: 0;
    transition: all .3s ease;
}


.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.disabled-row {
    background-color: #dcdcdc;
    pointer-events: none;
}

.s-24 {
    font-size: 24px !important;
}

.s-14 {
    font-size: 14px !important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: .5rem;
    font-weight: 300;
    line-height: 1.2;
    color: #606676;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: .5rem;
}

.paper-card {
    background: #fff;
    padding: 40px;
    box-shadow: 0 0 6px rgba(0, 0, 0, .1);
}