body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #f5f8fa !important;
  color: #86939e !important;
  font-family: Calibri !important;
  font-size: 14px !important;
  height: 100% !important;
  line-height: 1.5 !important;
  font-weight: 300 !important;
  letter-spacing: 0.2px !important;
  padding-right: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #707070 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  width: 5px;
  border-radius: 7px;
  z-index: 99;
  right: 1px;
  height: 535.837px;
}

.table td,
.table th {
  vertical-align: middle;
}

.table td,
.table th {
  padding: 0.75rem !important;
  border-top: 1px solid #e1e8ee;
}

.progress.progress-xs {
  height: 0.25rem;
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.btn-group-sm > .btn,
.btn.btn-sm {
  font-size: 0.8rem;
  padding: 0.35rem 0.6rem;
}

.box a {
  color: #86939e;
  font-size: 12px;
}

.btn-danger {
  border-color: transparent;
  color: #fff !important;
}

.btn {
  border-radius: 3px;
  font-weight: 300;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.float-right {
  float: right !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.btn-danger {
  color: #fff;
  background-color: #ed5564;
  border-color: #ed5564;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn {
  cursor: pointer;
}

.sidebar:hover {
  overflow-y: scroll !important;
}

.checkbox-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.checkbox-item {
  display: flex;
  align-items: center;
  justify-content: left;
  width: calc(33.33% - 10px);
  margin-bottom: 10px;
}

.checkbox-item label {
  margin-top: 10px;
}
