.default-sidebar {
    background-color: white;
    color: black;
}

.default-sidebar {
    position: fixed;
    height: 100%;
    top: 0;
    z-index: 999;
    transition: all .2s ease;
}

.customList>li.header {
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
    color: #86939e;
    font-size: 12px;
    padding: 10px 25px 10px 15px;
    height: fit-content;
}

.default-sidebar .customList li a {
    padding-top: 12px;
    padding-bottom: 12px;
}

.light {
    background-color: #f5f8fa;
}

.customList>li>a {
    display: block;
    padding: 10px 5px 10px 15px;
    color: #86939e;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: .4px;
}

.default-sidebar>.side-navbar a i {
    color: black;
}

.default-sidebar>.side-navbar.shrinked a span {
    display: block !important;
}

/* .active {
    color: #e76c90 !important;
} */
.page-content {
    align-items: stretch;
    display: flex;
}

.default-sidebar>.side-navbar.shrinked {
    min-width: 120px;
    max-width: 120px;
    text-align: center;
    transition: all .3s ease;
}

.default-sidebar>.side-navbar {
    min-width: 240px;
    max-width: 240px;
    color: #fff;
    z-index: 999;
    position: relative;
    height: 100%;
    padding: 80px 0;
    display: block;
    transition: all .3s ease;
}

.box-scroll {
    overflow-x: auto !important;
}

@media (max-width: 1200px) {
    .compact-light-sidebar>.side-navbar.shrinked, .compact-sidebar>.side-navbar.shrinked, .default-sidebar>.side-navbar.shrinked {
        margin-left: 0;
    }
}

@media (max-width: 1200px) {
    .compact-light-sidebar>.side-navbar, .compact-sidebar>.side-navbar, .default-sidebar>.side-navbar {
        margin-left: -90px;
        min-width: 90px;
        max-width: 90px;
        text-align: center;
    }
}

.default-sidebar>.side-navbar ul {
    padding: 10px 0;
}

.list-inline, .list-unstyled {
    padding-left: 0;
    list-style: none;
}

.customList>li {
    border-bottom: 1px solid #f8f8f8;
}

li, ol, ul {
    list-style: none;
}

ul {
    margin: 0;
    padding: 0;
}

.default-sidebar>.side-navbar.shrinked a {
    padding: 10px 0;
}

.default-sidebar>.side-navbar.shrinked a {
    padding: 15px 10px;
    border: none;
    font-size: .7rem;
    transition: color .3s, background .3s;
}

@media (max-width: 1200px) {
    .compact-light-sidebar>.side-navbar ul a, .compact-sidebar>.side-navbar ul a, .default-sidebar>.side-navbar ul a {
        padding: 10px 5px;
        border: none;
        font-size: .85rem;
    }
}

.default-sidebar>.side-navbar.shrinked a i {
    margin-right: 0;
    margin-bottom: 5px;
    display: block;
    font-size: 1.7rem;
    transition: color .3s;
}

.default-sidebar>.side-navbar a i {
    font-size: 18px;
    margin-right: 10px;
    transition: none;
    vertical-align: -4px;
    color: #8bc34a !important
}

@media (max-width: 1200px) {
    .compact-light-sidebar>.side-navbar ul a i, .compact-sidebar>.side-navbar ul a i, .default-sidebar>.side-navbar ul a i {
        margin-right: 0;
        margin-bottom: 5px;
        display: block;
        font-size: 1.6rem;
        transition: color .3s;
    }
}

.default-sidebar>.side-navbar.shrinked a span {
    display: block !important;
}